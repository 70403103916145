.users-list-header {
  justify-content: space-between; }
  .users-list-header .users-search {
    width: 280px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0; }
  .users-list-header .action-buttons button {
    margin-left: 10px; }

.users .gravatar {
  width: 48px; }
  .users .gravatar .avatar-wrapper {
    width: 48px;
    height: 48px; }
    .users .gravatar .avatar-wrapper .inicials {
      font-size: 13px; }

.users td.role-column,
.users th.role-column {
  width: 110px; }

.users td.last-login,
.users th.last-login {
  width: 140px;
  padding-right: 45px; }

.users th.username-col {
  width: 24%; }

.users td.email-col {
  word-break: break-all; }

.users td.last-login.never {
  line-height: 17px; }

.users td.last-login > span {
  color: #fabe53;
  font-size: 12px; }

.users td.last-login .resend-inv,
.users td.last-login .copy-inv-link {
  display: inline-block;
  font-size: 12px;
  color: #fabe53;
  text-decoration: underline;
  cursor: pointer; }
  .users td.last-login .resend-inv:hover,
  .users td.last-login .copy-inv-link:hover {
    text-decoration: none; }
  .users td.last-login .resend-inv.sending, .users td.last-login .resend-inv.copying,
  .users td.last-login .copy-inv-link.sending,
  .users td.last-login .copy-inv-link.copying {
    cursor: wait; }

.users tr.disabled-user td {
  color: #c4c4c4; }

.users-list .modal-wrapper .modal .form-row .regulations-note {
  font-size: 11px;
  text-align: center;
  margin-bottom: 0;
  margin-top: 11px; }

.users-list .modal-wrapper .modal .form-row.regulations-note-wrapper {
  margin-bottom: 0; }

.users-list .modal-wrapper .modal .form-row.role-row {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .users-list .modal-wrapper .modal .form-row.role-row > label {
    margin: 0 10px 0 0;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1px; }

.users-list .modal-wrapper .modal .form-row .role-toggle .fieldset {
  margin: 0; }
