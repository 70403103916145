@font-face {
  font-family: "Neue Hans Kendrick";
  src: url("./fonts/NeueHansKendrick-Regular.woff2") format("woff2"), url("./fonts/NeueHansKendrick-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Neue Hans Kendrick";
  src: url("./fonts/NeueHansKendrick-Bold.woff2") format("woff2"), url("./fonts/NeueHansKendrick-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Neue Hans Kendrick";
  src: url("./fonts/NeueHansKendrick-Medium.woff2") format("woff2"), url("./fonts/NeueHansKendrick-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Neue Hans Kendrick";
  src: url("./fonts/NeueHansKendrick-Light.woff2") format("woff2"), url("./fonts/NeueHansKendrick-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Inter UI";
  src: url("./fonts/Inter-UI-Regular.woff2") format("woff2"), url("./fonts/Inter-UI-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Inter UI";
  src: url("./fonts/Inter-UI-Bold.woff2") format("woff2"), url("./fonts/Inter-UI-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Inter UI";
  src: url("./fonts/Inter-UI-Medium.woff2") format("woff2"), url("./fonts/Inter-UI-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Inter UI";
  src: url("./fonts/Inter-UI-Italic.woff2") format("woff2"), url("./fonts/Inter-UI-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic; }

/*
 * Sass form input placeholder css styling
 *
 * Usage: 
 *	@include placeholder {
 *   	font-style:italic;
 *   	color: white;
 *   	font-weight:100;
	*	}
 */
::-webkit-input-placeholder {
  color: #222222;
  opacity: 0.5; }

:-moz-placeholder {
  color: #222222;
  opacity: 0.5; }

::-moz-placeholder {
  color: #222222;
  opacity: 0.5; }

:-ms-input-placeholder {
  color: #222222;
  opacity: 0.5; }

.grey-label {
  color: #999999;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 15px;
  margin-bottom: 2px;
  font-weight: bold;
  letter-spacing: 1px;
  margin-top: 0; }

.form-row {
  position: relative;
  margin: 0 0 18px; }

.select-field label,
.select-field .label,
.text-field label,
.text-field .label,
.toggle-switch-wrapper label,
.toggle-switch-wrapper .label,
.schedule-form label,
.schedule-form .label,
.variables-form label,
.variables-form .label,
.json-form label,
.json-form .label {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 6px;
  display: inline-block;
  letter-spacing: 1px;
  color: #222222; }

.select-field .full-width,
.text-field .full-width,
.toggle-switch-wrapper .full-width,
.schedule-form .full-width,
.variables-form .full-width,
.json-form .full-width {
  width: 100%;
  box-sizing: border-box; }

.select-field .error-message,
.text-field .error-message,
.toggle-switch-wrapper .error-message,
.schedule-form .error-message,
.variables-form .error-message,
.json-form .error-message {
  margin: 4px 0 0 17px;
  font-size: 11px;
  line-height: 13px;
  font-weight: bold;
  color: #ed382a; }

.text-field input,
.text-field textarea,
.text-field .condition-input {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  font-family: "Inter UI", sans-serif;
  font-size: 15px;
  line-height: 18px;
  padding: 12px 15px;
  transition: all 0.15s ease-out;
  background: #ffffff; }
  .text-field input:hover,
  .text-field textarea:hover,
  .text-field .condition-input:hover {
    border-color: #999999; }
  .text-field input:focus,
  .text-field textarea:focus,
  .text-field .condition-input:focus {
    outline: none;
    border-color: #fabe53;
    box-shadow: 0 1px 3px 0px rgba(254, 127, 102, 0.25); }
  .text-field input:disabled,
  .text-field textarea:disabled,
  .text-field .condition-input:disabled {
    border-color: #c4c4c4; }

.text-field textarea {
  resize: vertical; }

.text-field.error input {
  border-color: #ed382a;
  box-shadow: 0 1px 3px 0px rgba(237, 56, 42, 0.25);
  animation: animation-shake 0.75s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px; }

.text-field.redux-password-field .password-wrapper {
  position: relative; }

.text-field.redux-password-field .password {
  padding-right: 35px; }

.text-field.redux-password-field .password-visible-icon {
  padding: 4px;
  position: absolute;
  right: 5px;
  bottom: 8px;
  cursor: pointer; }
  .text-field.redux-password-field .password-visible-icon .icon {
    color: #c4c4c4;
    transition: all 0.15s ease-out; }
  .text-field.redux-password-field .password-visible-icon:hover .icon {
    color: #fabe53; }

.select-field {
  min-width: 160px; }

.checkbox-field {
  position: relative; }
  .checkbox-field label {
    display: block;
    position: relative;
    padding-left: 26px;
    cursor: pointer;
    user-select: none;
    font-size: 15px;
    padding-top: 1px; }
    .checkbox-field label.disabled {
      cursor: not-allowed; }
    .checkbox-field label .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: #ffffff;
      border: 1px solid #c4c4c4;
      border-radius: 4px;
      transition: all 0.15s ease-out;
      text-align: center;
      font-size: 10px;
      padding-top: 2px;
      box-sizing: border-box; }
      .checkbox-field label .checkmark .icon-check {
        color: #ffffff; }
    .checkbox-field label:hover .checkmark {
      border-color: #999999; }
    .checkbox-field label .checkbox {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0; }
      .checkbox-field label .checkbox:checked ~ .checkmark {
        background-color: #fabe53;
        border-color: #fabe53; }
      .checkbox-field label .checkbox:disabled ~ .checkmark {
        opacity: 0.5; }

.list .list-search {
  width: 300px;
  margin: 0 auto 20px; }

.table {
  font-weight: bold;
  width: 100%;
  border-collapse: collapse; }
  .table th,
  .table .table-head {
    font-size: 11px;
    letter-spacing: 1px;
    color: #999999;
    text-transform: uppercase;
    text-align: left; }
    .table th .sort-button,
    .table .table-head .sort-button {
      background: transparent;
      border: 0;
      font-size: 11px;
      letter-spacing: 1px;
      color: #999999;
      text-transform: uppercase;
      font-weight: bold;
      display: inline-block;
      padding: 0;
      position: relative;
      padding-right: 9px; }
      .table th .sort-button svg,
      .table .table-head .sort-button svg {
        position: absolute;
        right: 0;
        top: 1px; }
      .table th .sort-button:focus,
      .table .table-head .sort-button:focus {
        outline: none; }
      .table th .sort-button:hover,
      .table .table-head .sort-button:hover {
        color: #222222; }
      .table th .sort-button.active,
      .table .table-head .sort-button.active {
        color: #222222; }
  .table td,
  .table .table-cell {
    color: #222222;
    font-size: 14px;
    line-height: 17px; }
    .table td.bigger-font,
    .table .table-cell.bigger-font {
      font-size: 16px;
      line-height: 19px; }
    .table td.text-grey,
    .table .table-cell.text-grey {
      color: #999999;
      font-weight: 400; }
    .table td.status,
    .table .table-cell.status {
      text-transform: uppercase;
      font-family: "Inter UI", sans-serif;
      font-size: 11px;
      letter-spacing: 1px; }
    .table td.finished,
    .table .table-cell.finished {
      color: #41b05e; }
    .table td.warning,
    .table .table-cell.warning {
      color: #fbab62; }
    .table td.error,
    .table .table-cell.error {
      color: #ed382a; }
    .table td.canceled,
    .table .table-cell.canceled {
      color: #c4c4c4; }
  .table th,
  .table td,
  .table .table-head,
  .table .table-cell {
    padding: 10px 8px; }
    .table th.align-right,
    .table td.align-right,
    .table .table-head.align-right,
    .table .table-cell.align-right {
      text-align: right; }
    .table th.action-column,
    .table td.action-column,
    .table .table-head.action-column,
    .table .table-cell.action-column {
      padding: 0; }
      .table th.action-column.one-icon,
      .table td.action-column.one-icon,
      .table .table-head.action-column.one-icon,
      .table .table-cell.action-column.one-icon {
        width: 40px; }
      .table th.action-column.two-icon,
      .table td.action-column.two-icon,
      .table .table-head.action-column.two-icon,
      .table .table-cell.action-column.two-icon {
        width: 80px; }
    .table th.toggle-column,
    .table td.toggle-column,
    .table .table-head.toggle-column,
    .table .table-cell.toggle-column {
      width: 100px; }
  .table tbody tr,
  .table tbody .table-row,
  .table .tbody tr,
  .table .tbody .table-row {
    border-bottom: 1px solid #e6e6e6; }
    .table tbody tr:last-child,
    .table tbody .table-row:last-child,
    .table .tbody tr:last-child,
    .table .tbody .table-row:last-child {
      border-bottom: 0; }
  .table.table-content-clickable-row tbody tr,
  .table.table-content-clickable-row tbody .table-row,
  .table.table-content-clickable-row .tbody tr,
  .table.table-content-clickable-row .tbody .table-row {
    transition: all 0.15s ease-out;
    border-bottom: 0;
    cursor: pointer; }
    .table.table-content-clickable-row tbody tr:hover,
    .table.table-content-clickable-row tbody .table-row:hover,
    .table.table-content-clickable-row .tbody tr:hover,
    .table.table-content-clickable-row .tbody .table-row:hover {
      background: rgba(250, 190, 83, 0.05); }
    .table.table-content-clickable-row tbody tr td,
    .table.table-content-clickable-row tbody tr .table-cell,
    .table.table-content-clickable-row tbody .table-row td,
    .table.table-content-clickable-row tbody .table-row .table-cell,
    .table.table-content-clickable-row .tbody tr td,
    .table.table-content-clickable-row .tbody tr .table-cell,
    .table.table-content-clickable-row .tbody .table-row td,
    .table.table-content-clickable-row .tbody .table-row .table-cell {
      border-bottom: 1px solid #cbccd0; }
      .table.table-content-clickable-row tbody tr td:first-child,
      .table.table-content-clickable-row tbody tr .table-cell:first-child,
      .table.table-content-clickable-row tbody .table-row td:first-child,
      .table.table-content-clickable-row tbody .table-row .table-cell:first-child,
      .table.table-content-clickable-row .tbody tr td:first-child,
      .table.table-content-clickable-row .tbody tr .table-cell:first-child,
      .table.table-content-clickable-row .tbody .table-row td:first-child,
      .table.table-content-clickable-row .tbody .table-row .table-cell:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px; }
      .table.table-content-clickable-row tbody tr td:last-child,
      .table.table-content-clickable-row tbody tr .table-cell:last-child,
      .table.table-content-clickable-row tbody .table-row td:last-child,
      .table.table-content-clickable-row tbody .table-row .table-cell:last-child,
      .table.table-content-clickable-row .tbody tr td:last-child,
      .table.table-content-clickable-row .tbody tr .table-cell:last-child,
      .table.table-content-clickable-row .tbody .table-row td:last-child,
      .table.table-content-clickable-row .tbody .table-row .table-cell:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px; }
    .table.table-content-clickable-row tbody tr:last-child td,
    .table.table-content-clickable-row tbody tr:last-child .table-cell,
    .table.table-content-clickable-row tbody .table-row:last-child td,
    .table.table-content-clickable-row tbody .table-row:last-child .table-cell,
    .table.table-content-clickable-row .tbody tr:last-child td,
    .table.table-content-clickable-row .tbody tr:last-child .table-cell,
    .table.table-content-clickable-row .tbody .table-row:last-child td,
    .table.table-content-clickable-row .tbody .table-row:last-child .table-cell {
      border-bottom: 0; }
  .table.table-content-clickable-row tbody th.hover-overlap-helper,
  .table.table-content-clickable-row tbody td.hover-overlap-helper,
  .table.table-content-clickable-row tbody .table-head.hover-overlap-helper,
  .table.table-content-clickable-row tbody .table-cell.hover-overlap-helper,
  .table.table-content-clickable-row .tbody th.hover-overlap-helper,
  .table.table-content-clickable-row .tbody td.hover-overlap-helper,
  .table.table-content-clickable-row .tbody .table-head.hover-overlap-helper,
  .table.table-content-clickable-row .tbody .table-cell.hover-overlap-helper,
  .table.table-content-clickable-row thead th.hover-overlap-helper,
  .table.table-content-clickable-row thead td.hover-overlap-helper,
  .table.table-content-clickable-row thead .table-head.hover-overlap-helper,
  .table.table-content-clickable-row thead .table-cell.hover-overlap-helper,
  .table.table-content-clickable-row .thead th.hover-overlap-helper,
  .table.table-content-clickable-row .thead td.hover-overlap-helper,
  .table.table-content-clickable-row .thead .table-head.hover-overlap-helper,
  .table.table-content-clickable-row .thead .table-cell.hover-overlap-helper {
    box-sizing: border-box;
    width: 5px;
    padding: 0;
    border-bottom: 0; }

.table {
  display: table; }
  .table .tbody {
    display: table-row-group; }
  .table .thead {
    display: table-header-group; }
  .table .table-row {
    display: table-row;
    text-decoration: none;
    text-transform: none; }
  .table .table-head,
  .table .table-cell {
    display: table-cell;
    vertical-align: middle; }

a {
  color: #777777;
  cursor: pointer;
  transition: all 0.15s ease-out;
  font-size: 11px;
  text-transform: uppercase;
  color: #777777;
  text-decoration: underline;
  outline: none; }
  a:hover {
    text-decoration: none; }
  a.button {
    text-decoration: none;
    box-sizing: border-box;
    display: flex;
    align-items: center; }
    a.button.small {
      line-height: 23px; }

pre {
  border: 1px solid #c4c4c4;
  padding: 20px 10px;
  overflow-x: auto;
  border-radius: 2px; }
  pre code {
    overflow-x: auto; }
  pre .string {
    color: #5d90cd; }
  pre .number {
    color: #46a609; }
  pre .boolean {
    color: #39946a; }
  pre .null {
    color: #000000; }
  pre .key {
    color: #000000; }

.job-list-page .jobs-chart {
  height: 200px;
  margin-bottom: 20px; }

.job-list-page .job-list-content {
  padding: 15px 10px; }
  .job-list-page .job-list-content .no-jobs-message {
    color: #222222;
    text-align: center;
    margin: 10px 0;
    font-weight: bold;
    font-size: 18px; }
  .job-list-page .job-list-content .table thead th,
  .job-list-page .job-list-content .table thead .table-head,
  .job-list-page .job-list-content .table .thead th,
  .job-list-page .job-list-content .table .thead .table-head {
    padding-bottom: 4px; }
    .job-list-page .job-list-content .table thead th.action-column,
    .job-list-page .job-list-content .table thead .table-head.action-column,
    .job-list-page .job-list-content .table .thead th.action-column,
    .job-list-page .job-list-content .table .thead .table-head.action-column {
      width: 60px; }
    .job-list-page .job-list-content .table thead th.status,
    .job-list-page .job-list-content .table thead .table-head.status,
    .job-list-page .job-list-content .table .thead th.status,
    .job-list-page .job-list-content .table .thead .table-head.status {
      padding-left: 44px; }
  .job-list-page .job-list-content .table tbody td,
  .job-list-page .job-list-content .table tbody .table-cell,
  .job-list-page .job-list-content .table .tbody td,
  .job-list-page .job-list-content .table .tbody .table-cell {
    font-size: 14px;
    font-weight: normal;
    padding-top: 14px;
    padding-bottom: 14px; }
    .job-list-page .job-list-content .table tbody td.duration .running-seconds,
    .job-list-page .job-list-content .table tbody .table-cell.duration .running-seconds,
    .job-list-page .job-list-content .table .tbody td.duration .running-seconds,
    .job-list-page .job-list-content .table .tbody .table-cell.duration .running-seconds {
      font-weight: bold; }
    .job-list-page .job-list-content .table tbody td.action-column,
    .job-list-page .job-list-content .table tbody .table-cell.action-column,
    .job-list-page .job-list-content .table .tbody td.action-column,
    .job-list-page .job-list-content .table .tbody .table-cell.action-column {
      text-align: right; }
    .job-list-page .job-list-content .table tbody td .data-text span,
    .job-list-page .job-list-content .table tbody .table-cell .data-text span,
    .job-list-page .job-list-content .table .tbody td .data-text span,
    .job-list-page .job-list-content .table .tbody .table-cell .data-text span {
      color: #777777; }

.job-detail-page .job-detail-timeline {
  width: calc(50% - 10px);
  float: left; }
  .job-detail-page .job-detail-timeline .job-timeline-header {
    justify-content: space-between; }
  .job-detail-page .job-detail-timeline .job-detail-timeline-content {
    padding-left: 10px;
    padding-right: 10px; }
    .job-detail-page .job-detail-timeline .job-detail-timeline-content .table thead th,
    .job-detail-page .job-detail-timeline .job-detail-timeline-content .table thead .table-head,
    .job-detail-page .job-detail-timeline .job-detail-timeline-content .table .thead th,
    .job-detail-page .job-detail-timeline .job-detail-timeline-content .table .thead .table-head {
      padding-bottom: 4px; }
      .job-detail-page .job-detail-timeline .job-detail-timeline-content .table thead th.status,
      .job-detail-page .job-detail-timeline .job-detail-timeline-content .table thead .table-head.status,
      .job-detail-page .job-detail-timeline .job-detail-timeline-content .table .thead th.status,
      .job-detail-page .job-detail-timeline .job-detail-timeline-content .table .thead .table-head.status {
        padding-left: 44px; }
    .job-detail-page .job-detail-timeline .job-detail-timeline-content .table tbody td,
    .job-detail-page .job-detail-timeline .job-detail-timeline-content .table tbody .table-cell,
    .job-detail-page .job-detail-timeline .job-detail-timeline-content .table .tbody td,
    .job-detail-page .job-detail-timeline .job-detail-timeline-content .table .tbody .table-cell {
      font-size: 14px;
      font-weight: normal;
      padding-top: 14px;
      padding-bottom: 14px; }

.job-detail-page .job-detail-stats {
  width: calc(50% - 10px);
  float: right; }
  .job-detail-page .job-detail-stats .stats-table td {
    font-size: 14px;
    padding-top: 19px;
    padding-bottom: 18px;
    font-weight: normal; }
    .job-detail-page .job-detail-stats .stats-table td.duration span {
      color: #222222; }
    .job-detail-page .job-detail-stats .stats-table td.duration .running-seconds {
      font-weight: bold; }
    .job-detail-page .job-detail-stats .stats-table td.head-cell {
      font-size: 16px;
      font-weight: bold;
      color: #999999; }
    .job-detail-page .job-detail-stats .stats-table td span {
      color: #999999; }

.job-detail-page .job-timeline-stats:after {
  clear: both;
  content: "";
  display: table; }

.job-detail-page .no-results-message {
  color: #222222;
  text-align: center;
  margin: 10px 0;
  font-weight: bold;
  font-size: 18px; }

.job-detail-page .job-logs-header {
  margin-top: 20px;
  justify-content: space-between; }

.job-detail-page .job-logs-content .logs-table {
  table-layout: fixed;
  width: 100%; }
  .job-detail-page .job-logs-content .logs-table td {
    white-space: pre-wrap;
    font-family: monospace;
    font-weight: normal;
    vertical-align: top;
    overflow-wrap: break-word; }
    .job-detail-page .job-logs-content .logs-table td.level {
      font-size: 16px;
      line-height: 18px; }
    .job-detail-page .job-logs-content .logs-table td.time {
      font-size: 14px;
      line-height: 16px; }
    .job-detail-page .job-logs-content .logs-table td.text {
      font-size: 13px;
      line-height: 15px;
      overflow-x: hidden;
      display: block; }
    .job-detail-page .job-logs-content .logs-table td.error {
      color: #ed382a; }
    .job-detail-page .job-logs-content .logs-table td a {
      text-transform: none; }
  .job-detail-page .job-logs-content .logs-table th.level {
    width: 80px; }
  .job-detail-page .job-logs-content .logs-table th.time {
    width: 180px; }

.history-list-page .history-list-content {
  padding: 15px 10px; }
  .history-list-page .history-list-content .table thead th,
  .history-list-page .history-list-content .table thead .table-head,
  .history-list-page .history-list-content .table .thead th,
  .history-list-page .history-list-content .table .thead .table-head {
    padding-bottom: 4px; }
    .history-list-page .history-list-content .table thead th.action-column,
    .history-list-page .history-list-content .table thead .table-head.action-column,
    .history-list-page .history-list-content .table .thead th.action-column,
    .history-list-page .history-list-content .table .thead .table-head.action-column {
      width: 60px; }
    .history-list-page .history-list-content .table thead th.user-column,
    .history-list-page .history-list-content .table thead .table-head.user-column,
    .history-list-page .history-list-content .table .thead th.user-column,
    .history-list-page .history-list-content .table .thead .table-head.user-column {
      width: 20%; }
    .history-list-page .history-list-content .table thead th.modified-column,
    .history-list-page .history-list-content .table thead .table-head.modified-column,
    .history-list-page .history-list-content .table .thead th.modified-column,
    .history-list-page .history-list-content .table .thead .table-head.modified-column {
      width: 15%; }
  .history-list-page .history-list-content .table tbody td,
  .history-list-page .history-list-content .table tbody .table-cell,
  .history-list-page .history-list-content .table .tbody td,
  .history-list-page .history-list-content .table .tbody .table-cell {
    font-size: 14px;
    font-weight: normal;
    padding-top: 14px;
    padding-bottom: 14px; }
    .history-list-page .history-list-content .table tbody td.action-column,
    .history-list-page .history-list-content .table tbody .table-cell.action-column,
    .history-list-page .history-list-content .table .tbody td.action-column,
    .history-list-page .history-list-content .table .tbody .table-cell.action-column {
      text-align: right; }
    .history-list-page .history-list-content .table tbody td.author-text,
    .history-list-page .history-list-content .table tbody .table-cell.author-text,
    .history-list-page .history-list-content .table .tbody td.author-text,
    .history-list-page .history-list-content .table .tbody .table-cell.author-text {
      font-size: 16px;
      font-weight: bold; }

.history-item-page .history-item-header,
.activity-detail-history-page .history-item-header {
  justify-content: space-between; }
  .history-item-page .history-item-header .revert-button,
  .activity-detail-history-page .history-item-header .revert-button {
    flex-shrink: 0; }

.history-item-page .history-item-content .history-item-summary,
.history-item-page .activity-detail-history-content .history-item-summary,
.activity-detail-history-page .history-item-content .history-item-summary,
.activity-detail-history-page .activity-detail-history-content .history-item-summary {
  font-size: 14px;
  font-weight: normal; }
  .history-item-page .history-item-content .history-item-summary .modified,
  .history-item-page .activity-detail-history-content .history-item-summary .modified,
  .activity-detail-history-page .history-item-content .history-item-summary .modified,
  .activity-detail-history-page .activity-detail-history-content .history-item-summary .modified {
    width: 150px; }

.history-item-page .history-item-content .diff-view,
.history-item-page .activity-detail-history-content .diff-view,
.activity-detail-history-page .history-item-content .diff-view,
.activity-detail-history-page .activity-detail-history-content .diff-view {
  background: #f7f8f9;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  margin: 20px 10px 10px;
  overflow-x: auto; }
  .history-item-page .history-item-content .diff-view .css-1caozzd-split-view .css-1n7ec1i-line .css-1ks9aww-gutter,
  .history-item-page .activity-detail-history-content .diff-view .css-1caozzd-split-view .css-1n7ec1i-line .css-1ks9aww-gutter,
  .activity-detail-history-page .history-item-content .diff-view .css-1caozzd-split-view .css-1n7ec1i-line .css-1ks9aww-gutter,
  .activity-detail-history-page .activity-detail-history-content .diff-view .css-1caozzd-split-view .css-1n7ec1i-line .css-1ks9aww-gutter {
    min-width: auto;
    width: 3%;
    cursor: auto; }
  .history-item-page .history-item-content .diff-view .css-1caozzd-split-view .css-1n7ec1i-line .css-17vezug-marker,
  .history-item-page .activity-detail-history-content .diff-view .css-1caozzd-split-view .css-1n7ec1i-line .css-17vezug-marker,
  .activity-detail-history-page .history-item-content .diff-view .css-1caozzd-split-view .css-1n7ec1i-line .css-17vezug-marker,
  .activity-detail-history-page .activity-detail-history-content .diff-view .css-1caozzd-split-view .css-1n7ec1i-line .css-17vezug-marker {
    min-width: auto;
    width: 2%; }
  .history-item-page .history-item-content .diff-view .css-1caozzd-split-view .css-1n7ec1i-line .css-vl0irh-content,
  .history-item-page .activity-detail-history-content .diff-view .css-1caozzd-split-view .css-1n7ec1i-line .css-vl0irh-content,
  .activity-detail-history-page .history-item-content .diff-view .css-1caozzd-split-view .css-1n7ec1i-line .css-vl0irh-content,
  .activity-detail-history-page .activity-detail-history-content .diff-view .css-1caozzd-split-view .css-1n7ec1i-line .css-vl0irh-content {
    width: 45%;
    word-break: break-word; }
  .history-item-page .history-item-content .diff-view .css-1caozzd-split-view .css-1wdmqof-code-fold,
  .history-item-page .activity-detail-history-content .diff-view .css-1caozzd-split-view .css-1wdmqof-code-fold,
  .activity-detail-history-page .history-item-content .diff-view .css-1caozzd-split-view .css-1wdmqof-code-fold,
  .activity-detail-history-page .activity-detail-history-content .diff-view .css-1caozzd-split-view .css-1wdmqof-code-fold {
    background-color: #e9eaea; }
    .history-item-page .history-item-content .diff-view .css-1caozzd-split-view .css-1wdmqof-code-fold .css-1989f1g-code-fold-gutter,
    .history-item-page .activity-detail-history-content .diff-view .css-1caozzd-split-view .css-1wdmqof-code-fold .css-1989f1g-code-fold-gutter,
    .activity-detail-history-page .history-item-content .diff-view .css-1caozzd-split-view .css-1wdmqof-code-fold .css-1989f1g-code-fold-gutter,
    .activity-detail-history-page .activity-detail-history-content .diff-view .css-1caozzd-split-view .css-1wdmqof-code-fold .css-1989f1g-code-fold-gutter {
      background-color: #e9eaea; }
    .history-item-page .history-item-content .diff-view .css-1caozzd-split-view .css-1wdmqof-code-fold .css-o0xmtj-code-fold-content,
    .history-item-page .activity-detail-history-content .diff-view .css-1caozzd-split-view .css-1wdmqof-code-fold .css-o0xmtj-code-fold-content,
    .activity-detail-history-page .history-item-content .diff-view .css-1caozzd-split-view .css-1wdmqof-code-fold .css-o0xmtj-code-fold-content,
    .activity-detail-history-page .activity-detail-history-content .diff-view .css-1caozzd-split-view .css-1wdmqof-code-fold .css-o0xmtj-code-fold-content {
      color: #777777; }

.history-item-page .history-item-content pre,
.history-item-page .activity-detail-history-content pre,
.activity-detail-history-page .history-item-content pre,
.activity-detail-history-page .activity-detail-history-content pre {
  border: none;
  padding: 0;
  border-radius: 0; }

.history-item-page .history-item-content .pre-wrapper,
.history-item-page .activity-detail-history-content .pre-wrapper,
.activity-detail-history-page .history-item-content .pre-wrapper,
.activity-detail-history-page .activity-detail-history-content .pre-wrapper {
  background: #f7f8f9;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  margin: 10px; }
  .history-item-page .history-item-content .pre-wrapper pre,
  .history-item-page .activity-detail-history-content .pre-wrapper pre,
  .activity-detail-history-page .history-item-content .pre-wrapper pre,
  .activity-detail-history-page .activity-detail-history-content .pre-wrapper pre {
    white-space: pre-wrap;
    line-height: 25px;
    margin-left: 15px;
    margin-right: 15px; }

.history-item-page .history-item-content .label,
.history-item-page .activity-detail-history-content .label,
.activity-detail-history-page .history-item-content .label,
.activity-detail-history-page .activity-detail-history-content .label {
  font-size: 11px;
  letter-spacing: 1px;
  color: #999999;
  text-transform: uppercase;
  text-align: left;
  font-weight: bold;
  padding: 10px 8px 0px;
  margin: 30px 0 0; }

/*
 * ---------------------
 * Shake animation start
 * ---------------------
 */
@keyframes animation-shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0); }
  20%,
  80% {
    transform: translate3d(2px, 0, 0); }
  30%,
  50%,
  70% {
    transform: translate3d(-3px, 0, 0); }
  40%,
  60% {
    transform: translate3d(3px, 0, 0); } }

/*
 * -------------------
 * Shake animation end
 * -------------------
 */
/*
 * ----------------------
 * Rotate animation start
 * ----------------------
 */
@keyframes animation-rotate {
  100% {
    transform: rotate(360deg); } }

/*
 * --------------------
 * Rotate animation end
 * --------------------
 */
/*
  * -----------
  * Loading row
  * -----------
  */
.loading-row {
  position: relative;
  cursor: wait;
  width: 100%;
  min-height: 24px; }
  .loading-row:before {
    margin: -13px 0 0 -13px;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    content: "";
    border-radius: 24px;
    background-clip: padding-box;
    border-color: rgba(250, 190, 83, 0.25);
    border-width: 2px;
    border-style: solid;
    border-top-color: #fabe53;
    animation: animation-rotate 975ms cubic-bezier(0.25, 0.35, 0.5, 0.95) infinite; }
  .loading-row span {
    color: transparent; }

/*
 * FADE effect
 */
.fade-enter {
  opacity: 0.01; }

.fade-enter-active {
  transition: opacity 0.2s ease-in;
  opacity: 1; }

.fade-exit {
  opacity: 1; }

.fade-exit-active {
  transition: opacity 0.2s ease-out;
  opacity: 0.01; }

/*
 * NEW VERSION BAR MARGIN-TOP effect
 */
.versionbaranimation-enter {
  margin-top: -50px; }

.versionbaranimation-enter-active {
  transition: all 0.2s ease-in;
  margin-top: 0px; }

.versionbaranimation-exit {
  margin-top: 0px; }

.versionbaranimation-exit-active {
  transition: all 0.2s ease-in;
  margin-top: -50px; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.user-form-screens {
  height: 100%; }
  .user-form-screens .form-wrapper,
  .user-form-screens .caption {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; }
  .user-form-screens .form-wrapper {
    background-color: #fabe53;
    width: 480px;
    float: left;
    flex-direction: column;
    color: #222222; }
    .user-form-screens .form-wrapper .logo {
      max-width: 100%;
      width: 90px;
      height: 90px;
      display: block;
      margin-bottom: 20px; }
      .user-form-screens .form-wrapper .logo.rotating {
        animation: spin 2s linear infinite; }
    .user-form-screens .form-wrapper form,
    .user-form-screens .form-wrapper .okta-wrapper {
      width: 320px;
      padding: 24px 20px;
      box-sizing: border-box;
      background-color: #ffffff;
      border-radius: 4px;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1); }
      .user-form-screens .form-wrapper form h2,
      .user-form-screens .form-wrapper .okta-wrapper h2 {
        font-size: 28px;
        line-height: 33px;
        margin: 0 0 24px;
        text-align: center; }
    .user-form-screens .form-wrapper .recaptcha iframe {
      transform: scale(0.925);
      transform-origin: 0 0; }
    .user-form-screens .form-wrapper .actions {
      background: #f7f8f9;
      border-top: 1px solid #c4c4c4;
      padding: 20px;
      margin: 0 -20px -24px;
      position: relative;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px; }
      .user-form-screens .form-wrapper .actions.with-checkbox {
        padding-bottom: 16px; }
    .user-form-screens .form-wrapper .okta-login {
      height: 44px;
      width: 100%;
      display: block;
      background: #007dc1;
      color: #ffffff;
      font-family: "Inter UI", sans-serif;
      font-size: 13px;
      letter-spacing: 0.15em;
      text-decoration: none;
      font-weight: 500;
      text-transform: uppercase;
      border-radius: 4px;
      text-align: center;
      padding: 14px 0;
      box-sizing: border-box;
      transition: all 0.15s ease-out; }
      .user-form-screens .form-wrapper .okta-login.second-button {
        margin-top: 15px; }
      .user-form-screens .form-wrapper .okta-login img {
        height: 14px;
        width: auto;
        margin-bottom: -1px; }
      .user-form-screens .form-wrapper .okta-login.loading img {
        opacity: 0; }
      .user-form-screens .form-wrapper .okta-login:hover {
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1); }
      .user-form-screens .form-wrapper .okta-login:disabled {
        cursor: not-allowed;
        background: #c4c4c4;
        color: #ffffff;
        box-shadow: none; }
    .user-form-screens .form-wrapper .underform-link {
      display: table;
      text-align: center;
      margin: 15px auto 0;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 0.15em; }
      .user-form-screens .form-wrapper .underform-link.forgot-password {
        margin: 0;
        position: absolute;
        bottom: -30px;
        color: #ffffff;
        left: 50%;
        transform: translateX(-50%); }
    .user-form-screens .form-wrapper .general-terms {
      margin-top: 15px;
      padding: 0 14px;
      margin-bottom: 0; }
      .user-form-screens .form-wrapper .general-terms a {
        text-transform: none;
        font-size: 12px;
        color: #fabe53; }
      .user-form-screens .form-wrapper .general-terms .checkbox-label {
        font-size: 12px;
        line-height: 15px;
        text-transform: none;
        letter-spacing: 0;
        font-weight: 400;
        margin-bottom: 0; }
      .user-form-screens .form-wrapper .general-terms label .checkmark {
        top: 7px; }
  .user-form-screens .caption {
    background-color: #ffffff;
    float: right;
    width: calc(100% - 480px);
    display: flex;
    justify-content: center;
    align-items: center; }
    .user-form-screens .caption .content-wrapper {
      width: 565px; }
    .user-form-screens .caption h1 {
      font-size: 48px;
      line-height: 58px;
      margin: 0;
      color: #fabe53; }
    .user-form-screens .caption h2 {
      margin: 0;
      font-size: 40px;
      font-weight: 500;
      line-height: normal;
      color: #222222; }

html {
  height: 100%;
  color: #777777;
  font-size: 1em;
  margin-right: calc(-100vw + 100%);
  overflow-x: hidden;
  background-color: #eaeef1; }

body,
#root,
.app {
  height: 100%;
  margin: 0;
  background-color: #eaeef1; }

body {
  padding: 0;
  font-family: "Inter UI", sans-serif;
  font-size: 100%; }
  body.prevent-scrolling {
    overflow: hidden; }

.wrapper {
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative; }

p {
  margin: 0 0 8px; }
  p:last-child {
    margin-bottom: 0; }

h1 {
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 10px; }

h2 {
  font-size: 21px;
  line-height: 24px;
  margin: 0 0 8px; }

h3 {
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 8px; }

.ace-editor-wrapper {
  border: 1px solid #c4c4c4;
  padding: 15px 0 0;
  background: #dddddd;
  border-radius: 6px;
  position: relative; }
  .ace-editor-wrapper:before {
    content: "";
    width: 42px;
    height: 100%;
    background: #dddddd;
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }
  .ace-editor-wrapper.edit {
    background: #f7f8f9; }
  .ace-editor-wrapper.edit-white {
    background: #ffffff; }
  .ace-editor-wrapper.error {
    border-color: #ed382a;
    box-shadow: 0 1px 3px 0px rgba(237, 56, 42, 0.25);
    animation: animation-shake 0.75s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px; }
